
#cookie_modal {
  .modal-header {
    border-bottom: 1px solid $medi-orange;
  }
  .modal-footer {
    border-top: none;
    padding:0 15px 15px;
  }
  .submit_button {
    width:auto !important;
  }
}