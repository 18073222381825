.page {
  h1 {
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 50px;
  }
  h2{
    margin-top: 30px;
    font-size: 22px;
    margin-bottom:20px;
  }
  h3{
    font-size:18px;
    margin-bottom:10px;
  }
  p{
    font-size: 16px;
  }
  ul {
    padding-left:30px;
    li {
      list-style: disc;
      font-size: 16px;
    }
  }
  a{
    color:$medi-blue-light;
    text-decoration: underline;
  }
}
.reg_condition_table {
  font-size:14px;
  margin:0 auto 10px;width:100%;
  tr > td, tr > th {
    border:1px solid $medi-black;
    padding:2px 8px;
    &:not(:first-of-type)
    {
      text-align:center;
    }
  }
  thead {
    background: $medi-orange;
    tr {
      &.main_head_row {
        font-weight:500;
        font-size:15px;
      }
    }
  }
  tbody {
    tr {
      &.sub_head_row {
        background:#FEE466;
      }
      &.sub_head_row {
        font-weight:500;
        font-size:15px;
      }
      td {
        vertical-align:middle;
        table {
          margin:0 auto;
        }
        &.td_green {
          background: #63C973;
        }
        &.td_light_green {
          background: #BAFF99;
        }
        &.td_empty {
          background: rgba(179, 179, 179, 0.4);
        }
        &.td_not_allowed {
          background: #FFDEE8;
        }
      }
      a {
        background:#FEE466;
        color:$medi-black;
      }
    }
  }
}
.table_footer_note {
  display: block;
  margin:0 auto;
}
@media(max-width:576px)
{
  .impress_company_details {
    div {
      &.row {
        margin-bottom:15px;
      }
    }
  }
}

strong {
    font-weight: bold;
}

table {
    padding {
        margin-bottom: 0;
    }
}