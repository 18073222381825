#profile_menu {
  background: $medi-black url("/img/ph_account_profildekor.png") repeat-y top left;
  position: fixed;
  height: 100%;
  z-index: 2;
  color: white;
  padding-left: 6%;
  padding-top: 10px;
  #person_id {
    img {
      width:150px;
      height:150px;
    }
  }
  nav {
    margin-top: 100px;
    font-size: 20px;
    margin-right: -32px;
    ul li {
      margin-bottom: 10px;
      a {
        display: block;
        position: relative;
        padding:5px 20px;
        border-radius:10px;
        font-size:18px;
        .fa {
          position: absolute;
          top: 11px;
          right: 35px;
          font-size: 18px;
        }
        &:hover {
          background-color:$medi-gray-dark;
          -webkit-transition: background-color 0.8s;
          transition: background-color 0.8s;
        }
        &.active {
          background-color: $medi-orange;
          -webkit-transition: background-color 0.8s;
          transition: background-color 0.8s;
        }
      }
    }
  }
}

#person_id {
  position: relative;
  padding-right: 15%;
  h2 {
    font-size: 24px;
    margin-top: 80px;
  }
  h3 {
    font-size: 18px;
    font-weight: normal;
    font-style: italic;
    letter-spacing: 1px;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    color:$medi-orange;
  }
  img {
    border: 10px solid $medi-black;
    border-radius: 50%;
    position: absolute;
    bottom: -60px;
    right: -85px;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, xl)) {
  #profile_menu {
    padding-left:8%;
    #person_id {
      img {
        width:100px;
        height:100px;
        border: 7px solid $medi-black;
        bottom: -8px;
        right: -59px;
      }
    }
  }
}

@media screen and (max-width: map-get($grid-breakpoints, lg)) {
  #profile_menu {
    background: $medi-black;
    padding-left:15px;
    nav {
      ul {
        li {
          a {
            font-size:16px;
          }
        }
      }
    }
  }
}