/* Header */

#top_nav {
    background: $medi-head;
    padding: 15px;
    height: 70px;
    box-shadow: 0 0 20px 0 $header-box-shadow;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;

    img {
        height: 45px;
        width: 300px;
    }

    @media(max-width:400px) {
        img {
            max-width: 220px;
        }
    }
}

.main_nav_style {
    text-align: center;

    ul {
        li {
            position: relative;
            text-transform: uppercase;
            display: inline-block;

            &.account-age-congratulation {
                margin-right: 10px;
                text-transform: none !important;

                .years {
                    color: $medi-orange;
                }
            }

            a {
                padding: 0 8px;
                //border-right: 1px solid $medi-black;
                &.last_child {
                    border: none;
                }

                &:hover {
                    text-decoration: underline;
                }

                &.active {
                    //text-decoration:underline;
                    display: inline-block;
                    position: relative;
                }

                &.active::after {
                    content: '';
                    height: 4px;
                    width: 90%;
                    background: #000;
                    position: absolute;
                    left: 4.5%;
                    bottom: 0;
                }
            }

            .nav_border_helper {
                position: absolute;
                width: 3px;
                height: 15px;
                background: $medi-white;
                right: 0;
                top: 5px;
                border-right: 1px solid $medi-black;
            }
        }
    }
}