
i.fa.fa-pencil, i.fa.fa-trash-o, i.fa.fa-times, i.fa.fa-info-circle {
  color:$medi-gray-dark;
  cursor:pointer;
}
i.fa.fa-pencil {
  &:hover {
    color:$medi-blue-light;
  }
}
i.fa.fa-trash-o {
  font-weight:bold;
  &:hover {
    color:$medi-red;
  }
}
i.fa.fa-info-circle {
  &:hover {
    color:$medi-orange;
  }
}