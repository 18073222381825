#help_block_title {
  color: #222;
  font-size: 13px;
  background-color: $medi-white;
  border: 1px solid $medi-blue-light;
  clear: both;
  position: absolute;
  right: -170px;
  top: -25px;
  // ? z-index: 30;
  text-transform: none;
  padding: 8px;
  width: 285px;
  min-height:90px;
  display: none;
}
#help_block_title:after {
  content: url("/img/kisharomszog-p.png");
  position: absolute;
  //right: 12px;
  //bottom: -18px;
  left: -12.33px;
  top: 30px;
}
#help_block_cont + #help_block_title {
  display: block;
}
.info_steps
{
  position: relative;
  top: 3px;
  left: 5px;
}
#container-addresses .row {
  margin-bottom: -8px;
}