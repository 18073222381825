body {
  &.login {
    height: 100vh;
    background: url("/img/ph_account_logindekor.png") no-repeat;
    background-size: 100% 100%;
  }
  &.index_login{
    .submit_button{
      @media (max-width: 991px) {
        margin-bottom: 15px !important;
      }
    }
    .list_inline {
      display: flex;
      justify-content: center;
    }
  }
}

.tiny_warning {
  font-size:14px;
}

.login_container {

  .help-link{
    position: absolute;
    bottom: -25px;
    right: 10px;
    font-size: 14px;
  }

  .new-access{
    position: absolute;
    bottom: 1px;
  }

  .forgotten_cont{
    position: absolute;
    bottom: 60px;
  }

  .account-wall
  {
    margin-top: 20px;
    padding: 48px 40px 36px;
    background-color: #f7f7f7;
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    height:500px;
    position: relative;
    width:450px;

    input[type="text"],input[type="password"]{
      margin-bottom: 0 !important;
    }

    .submit_button{
      font-weight: 400;
      font-size: 18px;
      @media (max-width: 332px){
        min-width: 85px;
      }

    }

    .login_header {
      display: block;
      margin:0 auto;
      position: relative;
      height: 50px;
      .login_logo_container {
        img{
          width: 95%;
          height: auto;
        }
        .login_item_container {
          height:20px;
          display: block;
          .login_item {
            text-align:center;
            opacity: 0;
            position: absolute;
            right:0;
            img {
              opacity:1;
              width:15px;
              height:15px;
            }
          }
        }
        #login_logo {
          width:80%;
          height:auto;
          opacity:0;
          display: block;
          text-align: center;
        }
      }
    }

    .login-links {
      position: absolute;
      bottom: 30px;
      a.a {
        //display: block;
        font-size: 16px;
        color: $medi-gray-dark;
      }
      a{
        .fa{
          color: $medi-orange;
          margin-left: 5px;
          position: relative;
          top:2px;
          border-bottom: 1px solid #f7f7f7 !important;
        }
      }
    }

    .form-signin {
      padding: 15px;
      margin: 0 auto;

      .field-loginform-username, .field-loginform-password {
        height: 80px;
      }

      .form-control {
        position: relative;
        font-size: 16px;
        height: auto;
        padding: 3px 10px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        &:focus {
          z-index: 2;
        }
      }

      input[type="text"] {
        margin-bottom: -1px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .need-help {
        margin-top: 5px;
      }

      /* form starting stylings ------------------------------- */
      .material-input {
        position: relative;
        margin-bottom: 45px;

        input {
          font-size: 17px;
          padding: 10px 10px 10px 5px;
          display: block;
          width: 100%;
          border: none;
          border-bottom: 1px solid $medi-gray;
          background: transparent;

          &:focus {
            outline: none;
          }
        }

        /* active state */
        input:focus ~ label, input:valid ~ label {
          top: -20px;
          font-size: 14px;
        }
        /* NE VOND ÖSSZE A FELSŐ SORRAL!!!! */
        input:-webkit-autofill ~ label {
          top: -20px;
          font-size: 14px;
        }

        /* LABEL ======================================= */
        label {
          color: #999;
          font-size: 16px;
          font-weight: normal;
          position: absolute;
          pointer-events: none;
          left: 5px;
          top: 5px;
          transition: 0.2s ease all;
          -moz-transition: 0.2s ease all;
          -webkit-transition: 0.2s ease all;
        }

        /* BOTTOM BARS ================================= */
        .bar {
          position: relative;
          display: block;
          width: 100%;
        }
        .bar:before, .bar:after {
          content: '';
          height: 2px;
          width: 0;
          bottom: 0;
          position: absolute;
          background: $medi-orange;
          transition: 0.2s ease all;
          -moz-transition: 0.2s ease all;
          -webkit-transition: 0.2s ease all;
        }
        .bar:before {
          left: 50%;
        }
        .bar:after {
          right: 50%;
        }

        /* active state */
        input:focus ~ .bar:before, input:focus ~ .bar:after {
          width: 50%;
        }
      }
    }

    .has-error {
      label {
        color: $medi-red !important;
      }
      input {
        border: none !important;
        border-bottom: 1px solid $medi-red !important;
      }
    }

    .has-success input {
      border: none !important;
      border-bottom: 1px solid $medi-gray !important;
    }

    h1 {
      font-size:20px;
    }
  }

  .hover-style:hover {
    color:$medi-blue-light !important;
  }

  .forgotten_password {
    font-size: 14px;
    &.a {
      color: $medi-orange !important;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .grant-access{
    #choose-account-form {
      padding:15px 0;
    }
    .login_profil_icon {
      width:50px;
      height:50px;
      margin-right:10px;
    }
    .fa {
      background: $medi-gray-light;
      border: 1px solid $medi-gray-light;
      border-radius: 19px;
      padding: 7px 9px;
      font-size: 21px;
    }

    h1 {
      font-size:20px;
      line-height:26px;
    }

    .link_simple {
      display: block;
      padding-bottom: 10px;
    }
  }

  #overlay {
    position: absolute;
    width: calc( 100% - 16px );
    height: calc( 100% - 20px );
    background: #000 url(/img/loading.gif) center center no-repeat;
    opacity: 0.3;
    background-size: 70px 70px;
    z-index: 999;
    margin-top: 20px;
  }
}

.full_center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-right: -50%;
  margin-bottom: -50%;
}

@media (max-width: 480px) {
  body.login {
    padding-bottom: 0 !important;
  }
  .login_container .help-link {
    bottom: 1px !important;
  }
  .login_container .account-wall {
    margin-top: 0;
    width: 100%;
    height: 100vh;
    padding: 48px 25px;
    min-height: 550px;
  }
  .main_container {
    padding: 0 !important;
  }
  .full_center {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0 !important;
  }
  .forgotten_cont{
    bottom: 90px !important;
  }
  .new-access{
    bottom: 40px !important;
  }
}

@media (max-width: 450px) and (max-height: 700px){
  body.login {
    background-size: auto;
  }
}

@media (max-height: 450px){
  body.login {
    background-size: auto;
  }
}

@media (max-height: 250px){
  .full_center{
    margin-top: 100px;
  }
}