

/* Buttons */

@mixin submit_button($backgroundColor:$medi-orange, $textColor:$medi-white, $borderIsTextColor: 'false'){
  font-weight:500;
  display:inline-block;
  padding:4px;
  height:40px;
  cursor: pointer;
  font-size: $submitSize;
  border-radius: 10px !important;
  background-color:$backgroundColor;

  $borderColor : $backgroundColor;
  $borderWidth : 1px;
  @if $borderIsTextColor == 'true' {
    $borderColor : $textColor;
    $borderWidth : 2px;
  }

  border: $borderWidth solid $borderColor;
  color: $textColor;
  min-width:100px;
  letter-spacing:0.5px;
  &:hover {
    background-color:$backgroundColor;
    border: $borderWidth solid $borderColor;
    color:$textColor;
  }
  &:focus {
    background-color:$backgroundColor !important;
    border: $borderWidth solid $borderColor !important;
    outline: 0;
    color: $textColor;
  }
  &:visited {
    background-color: $backgroundColor;
    border: $borderWidth solid $borderColor;
    color:$textColor;
  }
  &:active {
    background-color:$backgroundColor;
    border: $borderWidth solid $borderColor;
    color:$textColor;
  }
  .fa {
    font-size:18px;
    position: relative;
    top:1px;
    &.fa-chevron-left {
      margin-right:5px;
    }
    &.fa-chevron-right {
      margin-left:5px;
    }
  }
}

.submit_button_white {
  @include submit_button($medi-white, $medi-orange, 'true');
}
.submit_button_grey {
  @include submit_button($medi-gray-dark, $medi-white);
}
.submit_button {
  @include submit_button();
}
.submit_button_transparent {
  @include submit_button(transparent, $medi-orange, 'true');
}
.submit_button_bigger {
  width:250px;
}
.submit_button_smaller {
  width:75px;
  padding:0 5px;
  font-size:16px;
  height:32px;
  min-width:0;
}
.submit_button_medium {
  width:90px;
  padding:0 5px;
  font-size:16px;
  height:32px;
  min-width:0;
}
a.submit_button_smaller {
  padding:3px 5px;
}

.submit_button_v_smaller {
  min-width:0;
  font-size:16px;
  height:32px;
  width:60px !important;
}

.submit_button_registration {
  width:auto;
  font-size:18px;
  padding:0 10px;
}

.submit_inline_with_link {
  span {
    height:40px;
    vertical-align: middle;
    display:table-cell;
    cursor:pointer;
  }
}

@mixin link($textColor : $medi-red, $weight : "bold", $afterContent : "\f054"){
  color:$textColor;
  font-weight:$weight;
  &::after {
    content: $afterContent;
    display:inline-block;
    font-family: FontAwesome;
    position: relative;
    left:5px;
    vertical-align: middle;
    font-size: 20px;
    font-weight: normal;
  }
  &:hover {
    color:$textColor !important;
  }
  &:focus {
    color:$textColor !important;
  }
  &:visited {
    color: $textColor !important;
  }
  &:active {
    color:$textColor !important;
  }
}
.link_medi_red {
  @include link();
}
.link_medi_orange {
  @include link($medi-orange, "400");
}
.link_medi_orange_no_arrow {
  @include link($medi-orange, "400", "");
}
.link_medi_blue {
  @include link($medi-blue-light, "400", "");
}

.submit_simple_link {
  padding:0;
  border: none;
  background: none;
  font-size: 16px;
  cursor:pointer;
  &:hover {
    color:$medi-blue-light;
  }
  /* font-family: SourceSansPro, "Open Sans", sans-serif; */
}

.scroll_button_container {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  height: 0;
  &.to_the_top {
    top: 0;
  }
  &.to_the_bottom {
    bottom: 230px;
  }
  i {
    display: inline-block;
    text-align: left;
    padding: 10px;
    background-color: #FFF;
    color: $medi-orange;
    border: 1px solid lightgrey;
    cursor:pointer;
    z-index:10;
    &:before {
      font-size:28px;
    }
  }
}
.btn_plus_minus{
  color: #fff;
  background-color: $medi-blue-light ;
  border-color: $medi-blue-light;
  padding: 0 4px ;
  border-radius: 5px;
  text-align: left;
}
.profession_btn_plus
{
  position: relative;
  bottom: 10px;
  left: 40px;
}

.profession_btn_minus {
  position: absolute;
  bottom: 10px;
  left: 65px;
}
