
$positions: top right bottom left;
$limit : 200;

@mixin setMargin ($margin, $marginPosition){
  margin-#{$marginPosition}:$margin + px;
}

@mixin marginList {
  @each $marginPosition in $positions{
    $margin : 0;
    @while $margin <= $limit {
      .margin_#{$marginPosition}_#{$margin} { @include setMargin($margin, $marginPosition) }
      $margin:$margin + 5;
    }
  }
}

@mixin setPadding ($padding, $paddingPosition){
  padding-#{$paddingPosition}:$padding + px;
}

@mixin paddingList {
  @each $paddingPosition in $positions{
    $padding : 0;
    @while $padding <= $limit {
      .padding_#{$paddingPosition}_#{$padding} { @include setPadding($padding, $paddingPosition) }
      $padding:$padding + 5;
    }
  }
}

@include marginList;

@include paddingList;


.control_form_group_by_margin_0 {
  .form-group {
    margin:0 !important;
  }
}
.padding_30 {
  padding:30px;
}
.padding_left_0 {
  padding-left:0;
}
.padding_right_0 {
  padding-right:0;
}
.padding_0 {
  padding:0;
}
.w_50 {
  width:50%;
}

.two_prism_section {
  div.prism_outter_container:nth-of-type(1){
    padding-left:0;
    padding-right:30px;
  }
  div.prism_outter_container:nth-of-type(2){
    padding-left:30px;
    padding-right:0;
  }
}


/* Borders */

$separatorWeight : 6px;
$separatorColor : $medi-black;


@mixin setSeparatorBorders(){
  @each $position in $positions{
    .separate_#{$position} {
      border-#{$position}:$separatorWeight solid $separatorColor
    }
  }
}

@include setSeparatorBorders;


@media (min-width:1200px){
  .container {
    width:1250px;
  }
}





