/*
@media(min-width:1050px){
  footer {
    position: fixed !important;
  }
}
*/
footer {
  background: $medi-gray-dark;
  padding: 5px 30px !important;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;
  margin: auto;
  .footer_container {
    text-align: center;
    padding-top:5px;
    .ml_version{
      color: $medi-white;
      font-size:10px;
    }
  }
  .nav {
    li {
      display: inline-block;
      color: white;
      a {
        color: white;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    li + li {
      padding-left: 20px;
    }
  }
}
@media(max-width: 1200px)
{
  footer {
    position: relative !important;
  }
}