
/*
 * Meyer reset stíluslap hozzáadás
 */
@import "support/reset";

/*
 * Bootstrap v4-alpha stíluslap hozzáadás
 * A bootstrap theme fájlt nem használjuk
 */
@import "boostrap/bootstrap";

/*
 * Font-awesome karakterek hozzáadása
 */
@import "fonts/fonts";

/*
 * JQuery UI hozzáadása
 */
@import "support/_jquery-ui";

/*
 * Own common stylesheets
 */

@import "styles_partials/variables";

@import "styles_partials/main-classes";

@import "styles_partials/sections";

@import "styles_partials/buttons";

@import "styles_partials/form";

@import "styles_partials/header";

@import "styles_partials/footer";

@import "styles_partials/custom-font-icons";

@import "styles_partials/modals";

/* Info_texts */
@import "styles_partials/info_text";

/* Info_texts */
@import "styles_partials/info_circle";

/* Help blokk */
@import "styles_partials/help_block";

/* Profile menu blokk */
@import "styles_partials/profile-menu";

/* hamburger menu */
@import "styles_partials/hamburger_menu";

/* Profile page */
@import "styles_partials/profile";

/* Login page */
@import "styles_partials/login";

/* Progress bar */
@import "styles_partials/progress_bar";

/* 404 page properties */
@import "styles_partials/not_found";

/* Accept cookies alert */
@import "styles_partials/cookie-policy";

@import "styles_partials/application_tile";

@import "styles_partials/pages";

@import "styles_partials/syntaxhighlight";

@media (max-width: 576px ) {
    .back_button_left {
        float: left;
    }

    .radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
        margin-top: 0px;
        margin-left: 4px;
    }

    .radio_label label {
        top: 5px;
    }

    body {
        padding-bottom: 80px;
    }

    .margin_top_275px {
        margin-top: 30px !important;
    }

    .step4_cont {
        margin-top: 30px !important;
        margin-bottom: 50px;
    }
}

/*@media (min-width: 992px) {
  #form-registration-step2{
    margin-left: 70px;
  }

}*/
@media (max-width: 992px) {
    .fix_margin_bottom {
        margin-bottom: 60px;
    }
}

@media (max-width: 768px) {
    #reg_img_left {
        display: block;
        margin: 0 auto;
        margin-top: 15px;
    }

    .margin_top_275px {
        margin-top: 50px !important;
    }
}

body {
    background: $medi-white;

    &.index_login {
        background: #333333 url("/img/medipass_belepes_hatter.png") top center no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
    }
}

#login {
    input[type="checkbox"] {
        margin-top: 7px;
    }
}

#welcome_text {
    color: #ffffff;

    h1 {
        font-size: 35px;
        font-weight: bold;
    }

    @media screen and (max-width: map-get($grid-breakpoints, sm)) {
        h1 {
            font-size: 32px;
        }
    }
}

/*button*/
.button_row {
    text-align: right;
}

.btn_plus_minus {
    color: #fff;
    background-color: $medi-blue-light;
    border-color: $medi-blue-light;
    padding: 0 4px;
    border-radius: 5px;
    text-align: left;
}

.profession-block {
    position: relative;

    .btn_minus {
        position: absolute;
        right: 20px;
        top: 7px;
    }
}


/*step1 */

.margin_top_275px {
    margin-top: 75px;
}

.more_padding_bottom {
    padding-bottom: 65px;
}

@media (max-width: 576px) {
    .reg_info_box {
        p {
            margin-bottom: 20px;
        }
    }
}

.reg_info_box {
    position: relative;
    height: 40px !important;
    /*p{
    position: absolute;
    bottom:0;
    left:0;
  }*/
    .btn {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .white-button {
        right: 130px;
    }
}

/* INFO CIRCLE*/
.info_icon {
    img {
        position: absolute;
        left: -10;
        top: 35px;
    }
}

/*container*/
.content_container {
    margin-top: 70px;
}

#success_text {
    text-align: center;

    h1 {
        text-transform: uppercase;
        font-size: 35px;
        font-weight: bold;
    }

    @media(max-width:480px) {
        h1 {
            font-size: 20px;
        }

        .step_icon {
            width: 30px;
            height: 30px;
        }
    }

    .step_icon, i {
        margin-right: 20px;
        margin-top: -10px;
    }

    &.activation_response {
        h1 {
            font-size: 22px;
        }

        i {
            position: relative;
            top: 5px;
        }
    }

    p.info_text_lead {
        font-size: 18px;
    }
}

/**Step2*/

.date_picker_style {
    margin-bottom: 10px;

    input {
        display: block;
        padding: .5rem .75rem;
        font-size: 1rem;
        line-height: 1.25;
        color: #55595c;
        background-color: #fff;
        background-image: none;
        background-clip: padding-box;
        border: 1px solid $medi-gray-light;
    }
}

/*Step3*/

//Checkbox larger

.checkbox_style input[type="checkbox"] {
    height: 23px;
    width: 23px;
    margin-left: 0;
}

/*.padding_left_30
{
  padding-left: 30px;
}*/

//
/*.select_style
{
  margin-left: -5px !important;
  margin-right: 13px;

}*/

#exam-text {
    color: $medi-red;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 15px;
}

#exam-text:hover {
    cursor: pointer;
}

/*Step4*/

.step4_cont {
    margin-bottom: 50px;

    label {
        font-weight: 700;
    }
}

@media (min-width: 768px) {
    .less_margin_row div:first-child {
        margin-right: -10.5px
    }
}

.more_margin_top {
    margin-top: 70px;
}
/* .less_margin_row div:nth-child(2) {
 margin-left: -7.5px
}*/
.forgot_password_cont {
    color: #999;
    margin: 1em 0;
}

.margin_top80px {
    margin-top: 80px;
}

/* gallery display */

.site_preview_container {
    a {
        display: block;
        opacity: 0.75;

        &:hover {
            opacity: 1.0;
        }

        img {
            width: 130px;
            height: 130px;
            margin-bottom: 5px;
        }
    }
}

@media (min-width: 992px) {
    .vertical_cont {
        display: table;
        height: 700px;
        width: 100%;
        align-items: center;
        //margin: 10px;
    }

    .vertical_content {
        display: table-cell;
        vertical-align: middle;
    }
}

@media (min-width: 576px) {
    .worktype_select_padding {
        padding-right: 1px;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .padding_left_20 {
        padding-left: 20px !important;
    }
}

.show_hide_password {
    .input-group-addon {
        position: relative;
        right: 25px;
        top: 5px;
        z-index: 13;
        display: block !important;
        padding: 0;
        margin: 0;
        background: none;
        border: none;
    }
}

.activation_error {
    li {
        &:before {
            color: $medi-orange;
            display: inline-block;
            margin-right: 2px;
            margin-left: 15px;
            //margin-left: 3px;
            position: relative;
            bottom: 1px;
            font-family: FontAwesome;
            content: "\f054";
        }

        a {
            &:hover {
                color: $medi-orange;
            }
        }
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 25px;
    height: 25px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.step1-intro {
    li {
        list-style: disc;
        margin-left: 30px;
    }
}


// ----------------------
// StudentCertificate
// ----------------------

.image-uploader {
    .submit_button {
        min-height: 40px;
        height: auto;
    }

    .pictureFromCamera {
        width: 80%;
        height: auto;
        margin-top: 16px;
    }

    .file-input, .camera-file-input {
        display: none;
    }
}

