
.info_circle_cont {
  font-size: 1.5em;
  background: url(/img/medipass_info.png) no-repeat;
  height: 22px;
  width: 22px;
  &:hover {
    background: url(/img/medipass_info-active.png) no-repeat;
    div:first-child {
      display: block !important;
    }
  }
}
.info_circle_title {
  display: none;
  height: 140px;
}

@media(max-width:1739px){
  .info_circle_title {
    position: absolute;
    width: 290px;
    right: -5px;
    top: -160px;
    padding: 15px;
    background: #FFFFFF;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: $medi-blue-light solid 2px;
    z-index: 8;
    p {
      font-weight: 500;
      font-size: 12px;
      margin: auto;
    }
    div:first-child {
     position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 20px 15px 0;
      border-color: #FFFFFF transparent;
      display: block;
      width: 0;
      z-index: 10;
      bottom: -20px;
      left: 257px;
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 21px 16px 0;
      border-color: #4DD0E1 transparent;
      display: block;
      width: 0;
      z-index: 0;
      bottom: -22px;
      left: 256px;
    }
  }
}

@media (min-width: 1740px) {
  .info_circle_title {
    //font-weight: 500;
    //font-size: 12px;
    position: absolute;
    width: 300px;
    right: -325px;
    top: -60px;
    padding: 5px;
    background: #FFFFFF;
    border: $medi-blue-light solid 1px;
    p {
      font-weight: 500;
      font-size: 12px;
      margin: auto;
    }
    div:first-child {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 20px 20px 20px 0;
      border-color: transparent #FFFFFF;
      display: block;
      width: 0;
      z-index: 1;
      margin-top: -20px;
      left: -20px;
      top: 50%;
    }
    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 20px 20px 20px 0;
      border-color: transparent $medi-blue-light;
      display: block;
      width: 0;
      z-index: 0;
      margin-top: -20px;
      left: -21px;
      top: 50%;
    }
  }
}

.info_steps
{
  position: relative;
  top: 3px;
  left: 5px;
  @media (max-width: 324px){
    margin-left: -1px;
  }
}
.info_step3
{
  position: relative;
  top: 30px;
  left: 5px;
}

.info_steps
{
  position: relative;
  top: 3px;
  left: 5px;
}
.info_step3
{
  position: relative;
  top: 30px;
  left: 5px;
}
@media (max-width: 576px) {
  .info_step3 {
    position: absolute;
    top: 5px;
    right: 10px;;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .info_step3 {
    position: absolute;
    top: 55px;
    right: 10px;;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .info_step3 {
    position: absolute;
    top: 55px;
    right: 15px;;
  }
}

@media (min-width: 1200px) {
  .info_step3 {
    position: absolute;
    top: 60px;
    right: 5px;
  }
}
@media (min-width: 1200px){
  #info_step3{
    .info_circle_title {
      top: -57px;
    }
  }
}


@media (max-width: 1200px){
  #info_step3{
    .info_circle_title {
      top: -170px;
    }
  }
}
#info_step3{
.info_circle_title {
  height:140px;
}
}
