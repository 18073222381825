

$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$black: #000 !default;
// stylelint-disable property-blacklist
@mixin transition($transition...) {
    @if $enable-transitions {
        @if length($transition) == 0 {
            transition: $transition-base;
        }
        @else {
            transition: $transition;
        }
    }

    @if $enable-prefers-reduced-motion-media-query {
        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }
}
// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem !default;

$modal-dialog-margin: .5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: null !default;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, .2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-box-shadow-xs: 0 .25rem .5rem rgba($black, .5) !default;
$modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-border-color: $border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: 1rem !default;
$modal-header-padding-x: 1rem !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform .3s ease-out !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: .75rem !default;
$alert-padding-x: 1.25rem !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;

$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;





// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff


.modal-open {
    // Kill the scroll on the body
    overflow: hidden;

    .modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

// Container that the modal scrolls within
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;
    // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
    // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
    // See also https://github.com/twbs/bootstrap/issues/17695
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    width: auto;
    margin: $modal-dialog-margin;
    // allow clicks to pass through for custom click handling to close modal
    pointer-events: none;
    // When fading in the modal, animate it to slide down
    .modal.fade & {
        @include transition($modal-transition);
        transform: $modal-fade-transform;
    }

    .modal.show & {
        transform: $modal-show-transform;
    }
    // When trying to close, animate focus to scale
    /*.modal.modal-static & {
        transform: $modal-scale-transform;
    }*/
}

.modal-dialog-scrollable {
    display: flex; // IE10/11
    max-height: subtract(100%, $modal-dialog-margin * 2);

    .modal-content {
        max-height: subtract(100vh, $modal-dialog-margin * 2); // IE10/11
        overflow: hidden;
    }

    .modal-header,
    .modal-footer {
        flex-shrink: 0;
    }

    .modal-body {
        overflow-y: auto;
    }
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: subtract(100%, $modal-dialog-margin * 2);
    // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
    &::before {
        display: block; // IE10
        height: subtract(100vh, $modal-dialog-margin * 2);
        content: "";
    }
    // Ensure `.modal-body` shows scrollbar (IE10/11)
    &.modal-dialog-scrollable {
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .modal-content {
            max-height: none;
        }

        &::before {
            content: none;
        }
    }
}

// Actual modal
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    // counteract the pointer-events: none; in the .modal-dialog
    color: $modal-content-color;
    pointer-events: auto;
    background-color: $modal-content-bg;
    background-clip: padding-box;
    border: $modal-content-border-width solid $modal-content-border-color;
    @include border-radius($modal-content-border-radius);
    @include box-shadow($modal-content-box-shadow-xs);
    // Remove focus outline from opened modal
    outline: 0;
}

$zindex-modal-backdrop: 1040 !default;
// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal-backdrop;
    width: 100vw;
    height: 100vh;
    background-color: $modal-backdrop-bg;
    // Fade for backdrop
    &.fade {
        opacity: 0;
    }

    &.show {
        opacity: $modal-backdrop-opacity;
    }
}
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    display: flex;
    align-items: flex-start; // so the close btn always stays on the upper right corner
    justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
    padding: $modal-header-padding;
    border-bottom: $modal-header-border-width solid $modal-header-border-color;
    @include border-top-radius($modal-content-inner-border-radius);

    .close {
        padding: $modal-header-padding;
        // auto on the left force icon to the right even when there is no .modal-title
        margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
    }
}

// Title text within header
.modal-title {
    margin-bottom: 0;
    line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    position: relative;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when there should be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
    padding: $modal-inner-padding;
}
$modal-footer-margin-between: 0.5rem;
// Footer (for actions)
.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center; // vertically center
    justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
    padding: 0.25rem;
    border-top: $modal-footer-border-width solid $modal-footer-border-color;
    @include border-bottom-radius($modal-content-inner-border-radius);
    // Place margin between footer elements
    // This solution is far from ideal because of the universal selector usage,
    // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
    > * {
        margin: calc($modal-footer-margin-between / 2);
    }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
    // Automatically set modal's width for larger viewports
    .modal-dialog {
        max-width: $modal-md;
        margin: $modal-dialog-margin-y-sm-up auto;
    }

    .modal-dialog-scrollable {
        max-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

        .modal-content {
            max-height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
        }
    }

    .modal-dialog-centered {
        min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);
        
        &::before {
            height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
        }
    }

    .modal-content {
        @include box-shadow($modal-content-box-shadow-sm-up);
    }

    .modal-sm {
        max-width: $modal-sm;
    }
}

@include media-breakpoint-up(lg) {
    .modal-lg,
    .modal-xl {
        max-width: $modal-lg;
    }
}

@include media-breakpoint-up(xl) {
    .modal-xl {
        max-width: $modal-xl;
    }
}
