
/* Main classes and properties */

html * {
  font-family: SourceSansPro, "Open Sans", sans-serif;
}
body {
  background: $medi-white;
  font-size: $usualSize;
  color:$medi-black;
  height: 100vh;
}
*:focus {
  outline: none !important;
}
.btn:focus {
  outline:none !important;
}
.pull-right{
  float: right;
}
.pull-left{
  float: left;
}
.text-center {
  text-align:center;
}
.text-right {
  text-align:right;
}
.text-left {
  text-align: left;
}
.own_col {
  padding: 0;
  margin: 0;
}
.main_container {
  padding-top:70px;
  padding-bottom:70px;
}
#header_balance {
  height:70px;
}
.uppercase {
  text-transform:uppercase;
}
.bg_gray {
  background:$medi-gray-light;
}
.semibold {
  font-weight:500;
}
.relative {
  position:relative;
}
.absolute {
  position:absolute;
}
.my_hidden {
  display:none;
}
.block {
  display:none;
}
.inline-block {
  display:inline-block;
}
.pointer {
  cursor:pointer;
}
#user_data_medi_icon {
  font-size:75px;
}
.modal_success {
  box-shadow: 0 -1px 22px $success-green;
}
.modal_danger {
  box-shadow: 0 -1px 22px $danger-red;
}
#loading_screen {
  display: none;
  #loading_bg {
    background:$medi-black;
    width: 100%;
    height: 10000px;
    overflow-y:hidden;
    opacity: .5;
    position: fixed;
    top:0;
    z-index: 500000;
  }
  #loading_icon {
    width:300px;
    height:150px;
    position: fixed;
    left: 45%;
    top: 45%;
    opacity:0.8;
    z-index: 500000;
  }
}
.mini_title{
  font-size: $miniTitleSize;
}

.simple_note{
  font-style:italic;
  color: $medi-gray-dark;
  font-size:16px;
}

.margin_top_30 {
    margin-top: 30px;
}