.tile_container {
    .tile_item_outer {
        padding: 0 15px;

        .tile_item {
            position: relative;
            border: 1px solid $medi-orange;
            box-shadow: 0 5px 10px 0 $header-box-shadow;
            height: 250px;
            cursor: pointer;
            -webkit-transition: All 0.5s ease-in-out;

            .tile_img_container {
                position: relative;

                img {
                    &.tile_site_image {
                        width: 100%;
                        height: auto;
                    }
                }

                .tile_site_logo {
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    left: 50%;
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    bottom: -22px;
                    background: $medi-white;
                    padding: 5px;
                    border-radius: 40px;
                    -webkit-transition: All 0.5s ease-in-out;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    &#tile_mmr_logo {
                        width: 48px;
                        height: 48px;

                        img {
                            width: 90%;
                            height: 90%;
                        }
                    }
                }
            }

            .tile_description_outer {
                padding: 0 15px;
                height: 150px;
                position: relative;

                .tile_title_container {
                    .tile_title {
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 500;
                        color: $medi-orange;

                        &.vakci_orange {
                            color: $vakci-orange;
                        }

                        &.vakci_blue {
                            color: $vakci-blue;
                        }

                        &.tromb_pink {
                            color: $tromb-pink;
                        }

                        &.medimagi_green {
                            color: $medimagi-green;
                        }
                    }
                }

                .tile_description {
                    position: relative;
                }
            }

            &:hover {
                background: rgba(179, 179, 179, 0.3);
                -webkit-transition: All 0.5s ease-in-out;
            }

            &:hover > .tile_img_container > .tile_site_logo {
                width: 70px !important;
                height: 70px !important;
                bottom: -30px;
            }
        }

        @media(max-width:767px) {
            .tile_item {
                height: auto;
                padding-bottom: 15px;
            }
        }

        @media(max-width:360px) {
            .tile_item {
                .tile_description_outer {
                    min-height: 155px;
                }
            }
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            .tile_item {
                .tile_description_outer {
                    height: 140px;
                }
            }
        }
    }
}
