body.not_found_page_bg {
  background: url("/img/ph_account_404-bg.png") center bottom 0px no-repeat;
  background-size:100% auto;
  .not_found_content_container {
    padding-top:30px;
    .not_found_img_container {
      img {
        width:100%;
        height:auto;
      }
    }
    .not_found_error_text {
      font-size:20px;
    }
    @media(min-width:1200px)
    {
      .not_found_error_text {
        position: relative;
        top: 50%;
        transform: translateY(50%);
      }
    }
  }

  footer {
    display: none;
  }
}