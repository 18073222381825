/*
.container-fluid.profile_layout {
    min-height:900px;
}*/
#profile_content {
    .info_box {
        position: absolute;
        background: white;
        border: 1px solid rgba(0,0,0,.2);
        z-index: 1000;
        width: 272px;
        padding: 9px 14px;
        font-size: 14px;
        border-radius: .3rem;
        top: 24px;
        right: -111px;
    }

    .confirmation_box {
        display: none;
        position: absolute;
        background: white;
        border: 1px solid rgba(0,0,0,.2);
        z-index: 1000;
        width: 175px;
        font-size: 14px;
        border-radius: .3rem;
        top: 24px;
        right: -63px;

        &.show {
            display: block !important;
        }

        h3 {
            background: #fff;
            text-transform: uppercase;
            text-align: center;
            border-bottom: 1px solid #323232;

            &:before {
                content: "\f014";
                font-family: FontAwesome;
                font-weight: bold;
                color: #c30201;
                margin-right: 5px;
            }
        }

        p {
            margin-bottom: 10px;
            font-weight: 500;
        }

        .btn-group .btn + .btn {
            margin-left: 15px !important;
        }
    }
}

.profile_block {
    margin-bottom: 50px;

    .profile_block_header {
        border-bottom: 1px solid $medi-black;
        padding-bottom: 5px;

        .profile_title {
            font-size: 22px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            margin-bottom: 0;
            font-weight: bold;
        }
    }

    .profile_row_container {
        border-bottom: 1px solid $medi-gray-light;

        &:last-of-type {
            border-bottom: none;
        }

        &.multi_row {
            border-bottom: none;

            .profile_row {
                border-bottom: 1px solid $medi-gray-light;

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }

        .profile_row {
            padding: 5px 0;

            &:last-of-type {
                border-bottom: none !important;
            }

            label {
                font-weight: 500;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;
            }

            i.fa.fa-trash-o, i.fa.fa-pencil, i.fa.fa-info-circle {
                position: relative;
                top: 3px;
                font-size: 20px;
            }

            &.emails_row {
                border-bottom: none;

                &:empty {
                    height: 0;
                    padding: 0;
                    display: none !important;
                    animation-play-state: paused;
                }

                div.profile_row:last-of-type {
                    border-bottom: none !important;
                }
            }
        }
    }

    .workplaceAddresses_row {
        p:first-of-type {
            font-weight: 500;
        }
    }

    .professions_row {
        img.work_active {
            width: 20px;
            margin-right: 5px;
            position: relative;
            bottom: 2px;
        }

        input.work_active {
            width: 20px;
            margin-right: 5px;
            position: relative;
            bottom: -2px;
            height: 16px;
        }

        input[type="checkbox"]:disabled {
            cursor: default;
        }
    }

    .profile_add_field_container {
        height: 30px;

        button.profile_add_field {
            background: none;
            border: none;
            padding: 0;
            margin: 5px 0 0 0;
            font-size: 16px;
            color: $medi-blue-light;

            img {
                margin-right: 5px
            }

            span {
                position: relative;
                top: 2px;
            }
        }
    }

    .profile_info_text {
        color: $medi-blue-light;
    }

    /* .profile_row {
    &:hover {
      background-color:$medi-head;
    }
  }
  .profile_row {
    i.fa.fa-trash-o, i.fa.fa-pencil {
      &:hover + .profile_row {
        background-color:red;
      }
    }
  }*/
}
.profile_edit_block {
  position:relative;
  background-color:$medi-head;
  .profile_form_footer {
    margin-top:15px;
    position: relative;
    @media(max-width:1400px){
      height:70px;
    }
    button[type="submit"] {
      margin-right:10px;
    }
    .delete_cross {
      width:77px;
    }
    .simple_note {
      position: absolute;
      bottom:0;
      right:0;
    }
  }
  /*button[type="submit"] {
    position: absolute;
    left:15px;
    bottom:15px;
  }*/
  /*.delete_cross {
    i.fa.fa-times {
      font-size: 20px;
    }
    .delete_string {
      color:$medi-gray-dark;
      margin-right:5px;
      position: relative;
      bottom:1px;
    }
    position: absolute;
    right: 20px;
    bottom: 10px;
  }*/
}
.profile_form {
  .form_label {
    font-weight:500;
  }
  input:disabled {
    background:none;
    border:none;
    cursor:auto;
  }
}

.profile_row {
  .multi_row_icon {
    i {
      font-size:16px;
      width:18px;
    }
  }
  p {
    & > span.multi_row_icon {
      & + span {
        margin-right:10px;
      }
    }
  }
}

.resp_profile_title {
  background: $medi-gray-dark;
  padding:15px 0;
  text-align:center;
  h2 {
    color:$medi-white;
    font-size:20px;
    margin-bottom:0;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, xl)) {
  .profile_layout {
    min-height:100vh;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, md)) {
  #profile_right_side {
    .site-contact {
      margin-top:30px !important;
    }
  }
}

@media screen and (max-width: 350px) {
  .profile_edit_block {
    .profile_form_footer {
      .simple_note {
        bottom:-15px;
      }
    }
  }
}

button.blue_text_button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: $medi-blue-light;

    img {
        margin-right: 5px
    }

    span {
        position: relative;
        top: 2px;
    }
}

button.red_text_button {
    background: none;
    border: 1px solid #ffcccc;
    border-radius: 4px;
    padding: 4px 10px;
    margin: 5px 0;
    font-size: 14px;
    color: red;
    //font-weight: bold;

    img {
        margin-right: 5px
    }

    span {
        position: relative;
        top: 2px;
    }
}

/*
i.fa.fa-pencil[title] {position: relative;}

i.fa.fa-pencil[title]:hover:after {
  content: attr(title);
  position: absolute;
  left: 50%;
  top: 0.5em;
  width: 10em;
  padding: 2em;
  font-size: smaller; border: 1px solid;
  background: yellow;
  color: red;
}
*/