@mixin placeholders(){
  select,input::-webkit-input-placeholder {
    color: $medi-gray-dark !important;
    font-size:$placeholderSize;
  }
  select,input:-moz-placeholder { /* Firefox 18- */
    color: $medi-gray-dark !important;
    font-size:$placeholderSize;
  }
  select,input::-moz-placeholder {  /* Firefox 19+ */
    color: $medi-gray-dark !important;
    font-size:$placeholderSize;
  }
  select,input:-ms-input-placeholder {
    color: $medi-gray-dark !important;
    font-size: $placeholderSize;
  }
}

@mixin formStyle() {

    @include placeholders();

    label {
        font-size: $labelSize;
    }

    input[type="text"], input[type="password"], input[type="number"], select {
        height: $inputHeight !important;
        border-radius: 0;
        margin-bottom: 12px !important;
        border: 1px solid $medi-gray;
        padding: 6px;
    }

    input:read-only {
        background-color: $medi-gray-light;
    }

    input.hasDatepicker {
        width: 100%;
    }

    textarea {
        border: 1px solid #B3B3B3;
        min-height: $inputHeight;
        max-width: 100%;
    }

    select {
        padding: 0 30px 0 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url(/img/arrow-down-icon.png) no-repeat right 4px top 4px;
        background-size: 21px 21px;
        background-color: $medi-white;
    }

    @-moz-document url-prefix() {
        select {
            color: $bootstrap-gray;
            color: rgba(0,0,0,0) !important;
            text-shadow: 0 0 0 $bootstrap-gray;
        }
    }

    .form-group, .form-control, .form-horizontal {
        margin-bottom: 0;
    }

    select.invalid, input.invalid {
        border: 1px solid $medi-red !important;
    }

    .help-block-error {
        font-size: 14px;
        color: $medi-red;
        font-style: italic;
    }

    .has-success {
        select, input {
            border: 1px solid $success-green !important;
        }
    }

    .fa-eye-slash:hover {
        cursor: pointer;
    }
}

.simple_form {
  @include formStyle();
}

@media screen and (min-width: map-get($grid-breakpoints, sm)) {
  .triple_input_inline {
    .form-group:nth-of-type(1), .form-control:nth-of-type(1) {
      div.input_outter_container {
        padding-right:6px;
      }
    }
    .form-group:nth-of-type(2), .form-control:nth-of-type(2) {
      div.input_outter_container {
        padding-left:6px;
        padding-right:6px;
      }
    }
    .form-group:nth-of-type(3), .form-control:nth-of-type(3) {
      div.input_outter_container {
        padding-left:6px;
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, sm)){
.double_input_inline {
  .form-group:nth-of-type(1), .form-control:nth-of-type(1) {
    div.input_outter_container {
      padding-right: 6px;
    }
  }
  .form-group:nth-of-type(2), .form-control:nth-of-type(2) {
    div.input_outter_container {
      padding-left: 6px;
    }
  }
}
}

.address_card {
  padding:15px 15px 5px;
  box-shadow: 0 0 8px $header-box-shadow;
  max-width:573px;
  position: relative;
  .collapse_arrow {
    width:25px;
    height:auto;
    &.down {
      position: absolute;
      bottom:15px;
      right:15px;
    }
    &.up {
      float:right;
      position: relative;
      top:50%;
    }
  }
}

.field-loginform-rememberme {
  label {
    color: $medi-gray-dark;
  }
}

.checkbox_style input[type="checkbox"] {
  height: 23px;
  width: 23px;
  margin-left:0;
}

.profession-block {
  position: relative;
  .btn_minus {
    position: absolute;
    right:20px;
    top:7px;
  }
}

.popover.confirmation {
  width:175px;
  .popover-title {
    background: #FFF;
    text-transform: uppercase;
    text-align: center;
    &:before {
      content: "\f014";
      font-family:FontAwesome;
      font-weight:bold;
      color:$medi-red;
      margin-right:5px;
    }
    border-bottom:1px solid $medi-black;
  }
  .popover-content {
    .confirmation-content {
      margin-bottom:10px;
      font-weight:500;
    }
    .confirmation-buttons {
      .btn-group .btn + .btn {
        margin-left:15px !important;
      }
    }
  }
}

.simple_form {
  .add_profession {
    position: relative;
    bottom:5px;
  }
}