// Width and height

.w-100 { width: 100% !important; }
.h-100 { height: 100% !important; }

// Margin and Padding

.mx-auto {
  margin-right: auto !important;
  margin-left:  auto !important;
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x:   map-get($lengths, x);
    $length-y:   map-get($lengths, y);

    .#{$abbrev}-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
    .#{$abbrev}t-#{$size} { #{$prop}-top:    $length-y !important; }
    .#{$abbrev}r-#{$size} { #{$prop}-right:  $length-x !important; }
    .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y !important; }
    .#{$abbrev}l-#{$size} { #{$prop}-left:   $length-x !important; }

    // Axes
    .#{$abbrev}x-#{$size} {
      #{$prop}-right:  $length-x !important;
      #{$prop}-left:   $length-x !important;
    }
    .#{$abbrev}y-#{$size} {
      #{$prop}-top:    $length-y !important;
      #{$prop}-bottom: $length-y !important;
    }
  }
}

// Positioning

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-navbar-fixed;
}
