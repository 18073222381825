
ul.progress-bar {
  background-color:transparent !important;
}

@media (max-width: 991px) {
  $bar-inactive: #b3b3b3;
  $bar-border-inactive: $bar-inactive;
  $bar-circle-radius: 30px;
  $bar-step-name-margin: 5px;
  $bar-font-size: $bar-circle-radius - 18px;
  $bar-border-width: 2px;
  $bar-line-thickness: 2px;

  .progress-bar{
    li {
      list-style-type: none;
      width: 25%;
      float: left;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: $bar-inactive;
      &:before {
        content: '';
        line-height: $bar-circle-radius;
        display: block;
        text-align: center;
        margin: 5px auto 5px auto;
        border-radius: 50%;


        background:url("/img/medipass_status-inactive.png");
        background-size: 100% 100%;
        width: 24px;
        height: 24px;
        color: white;
      }
      &:after {
        width: 100%;
        height: $bar-line-thickness;
        content: '';
        position: absolute;
        background-color: $bar-inactive;
        top: calc(($bar-circle-radius + 2*$bar-border-width - $bar-line-thickness)/2);
        left: -50%;
        z-index: - 1;
      }
      &:first-child:after {
        content: none;
      }
      &.stop{
        &:before {
          background:url("/img/phaccount_status-current.png") !important;
          width: 24px;
          height: 24px;
          background-size: 100% 100%;
        }
      }
      &.done {
        &:before {
          background:url("/img/phaccount_status-done.png") !important;
          width: 24px;
          height: 24px;
          background-size: 100% 100%;

        }
      }
      &.done {
        &:before {
          background:url("/img/medipass_status-done.png");
        }
        & + li:after {
          background-color: $medi-orange;
        }
      }
    }
  }
}

@media (min-width: 992px){
  ul.progress-bar {
    height: 700px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //overflow: hidden;
  }
  ul.progress-bar::after {
    content: "";
    position: absolute;
    top: 0;
    left: 11px;
    background: #b3b3b3;
    width: 2px;
    height: 100%;
  }

  ul.progress-bar li {
    background:url("/img/phaccount_status-done.png");
    background-size: 100% 100%;
    //border-radius: 100px;
    width: 24px;
    height: 24px;
    z-index: 1;
    position: relative;
  }
  ul.progress-bar li.stop{
    background:url("/img/phaccount_status-current.png") !important;
    background-size: 100% 100%;
    background: #b3b3b3;
  }
  ul.progress-bar li.stop ~ li {
    background:url("/img/medipass_status-inactive.png") !important;
    background-size: 100% 100%;
    background: #b3b3b3;
  }
  ul.progress-bar li.stop ~ li::after {
    height: 0;
  }
  ul.progress-bar li::after {
    content: "";
    position: absolute;
    bottom: 22px;
    left: 11px;
    background: $medi-orange;
    width: 2px;
    height: 205px;
  }
  ul.progress-bar li:first-child::after {
    height: 0;
  }
}