#menuToggle
{
  display: block;
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 10; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;


  background: #000000;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}

#menu
{
  &.logged_in, &.logged_out{
    overflow-y: scroll;
    color: white;
    position: absolute;
    top:45px;
    width: 330px;
    height:103vh;
    margin: 0;
    padding:50px 25px;
    right: -30px;
    background: #323232;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    text-align: left;
    li
    {
      padding: 10px 0;
      font-size: 20px;
      a {
        display: block;
        position: relative;
        padding:5px 20px 5px 45px;
        border-radius:10px;
        font-size:18px;
        .fa {
          position: absolute;
          top: 2px;
          right: 10px;
          font-size: 18px;
        }
        &.active{

          border: 1px solid #FEC23E;
          background-color: #FEC23E;
        }
      }
      &.menu-item-has-children {
        >a {
          display: none;
        }
      }
    }
    .login{
      border-radius: 0;
      border-top: 1px solid white;
      a {
        padding-left: 0;
      }
        .fa{
        top: 12px;
      }
    }
    .logout{
      a {
        padding-left: 0;
      }
      .fa{
        top: 12px;
      }
    }
    #person_id{
      padding-right: 0;
      h2{
        margin-top: 30px;
      }
    }
    .fa-chevron-right{
      position: absolute;
     //top: 11px;
      right: 35px;
      font-size: 18px;
    }
    .fa-chevron-left{
      position: absolute;
      padding-top: 6px;
      left: 10px;
      font-size: 18px;
    }

  }
}


#menuToggle input:checked ~ #menu
{
  transform: scale(1.0, 1.0);
  opacity: 1;
}

.hambi_profile{
  img{
    height: 100px !important;
    width: 100px !important;
  }
}
