@font-face {
  font-family: SourceSansPro;
  src: url(/fonts/SourceSansPro-Semibold.otf);
  font-weight: 500;
}
@font-face {
  font-family: SourceSansPro;
  src: url(/fonts/SourceSansPro-Bold.otf);
  font-weight: 700;
}
@font-face {
  font-family: SourceSansPro;
  src: url(/fonts/SourceSansPro-Regular.otf)
}
@font-face {
  font-family: SourceSansPro;
  src: url(/fonts/SourceSansPro-It.otf);
  font-style: italic;
}
.semibold, b {
  font-weight: 500 !important;
}
.underlined {
  text-decoration: underline;
}
.italic, em {
  font-style: italic;
}
a, a:hover, a:focus {
  text-decoration: none;
  color: inherit;
}
a:focus {
  outline: none;
}
sup{
  font-size: smaller;
  vertical-align: super;
}
sub{
  vertical-align: sub;
  font-size: smaller;
}

