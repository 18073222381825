.modal {
    .modal-header {
        button {
            display: none; 
        }
    }
}


.modal.fade.show {
    opacity: 1; //todo kuka ha bootstrap 4 lesz
}

.step1-intro {
    p {
        margin-bottom: 0;
    }
}

.common_modal {
  @media(min-width:992px) {
    .modal-dialog {
      top:100px;
    }
  }
  .modal-header {
    display:none;
  }
  .modal-dialog {
    .modal-body {
      text-align:center;
      padding-bottom:0;
      .modal_signal_icon {
        margin-bottom:10px;
      }
    }
    .modal-footer {
      text-align:center;
    }
  }
}

#common_modal_response_success {
  .modal-dialog {
    max-width:300px;
    img {
      width:37px;
      height:37px;
    }
  }
}
#common_modal_response_failure {
  .modal-dialog {
    max-width: 450px;
  }
}
.modal_signal_icon {
  color:$medi-red;
  font-size:44px;
}