@mixin info_text($text_color,$size:16px) {
  p {
    margin-bottom: 0;
    margin-top:5px;
    color: $text_color;
    font-style: italic !important;
    font-weight: 400;
    //line-height: $size;
    font-size: $size !important;
    //padding-left: 10px;
  }
}

.reg_info_small {
  @include info_text($medi-gray-dark, 14px)
}
.reg_info_normal {
  @include info_text($medi-gray-dark, 15px)
}
