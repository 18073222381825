/* Color variables */

$medi-red:#C30201;

$medi-orange:#FEC23E;

$medi-white: #FFF;

$medi-blue-light: #00B7D3;

/* Disabled input fields background, background of search fields, profile block separator lines */
$medi-gray-light: #E5E5E5;

/* Background of the header section */
$medi-head:#F6F6F6;

/* Footer, input placeholder font color  */
/*$medi-gray-dark:#86827E;*/
$medi-gray-dark:#808080;

$header-box-shadow: #999999;

/* Input border */
$medi-gray:#B3B3B3;

$bootstrap-gray:#55595C;

$medi-black:#323232;

$success-green:#4cae4c;

$danger-red:#a94442;

$vakci-orange:#EB5C0D;

$vakci-blue: #0000C9;

$tromb-pink: #D9058E;

$medimagi-green:#1D7E7F;

/* Font variables sizes in PIXELS!!! */

/* Usually for the body */

$usualSize        : 16px;

/* Forms */

$placeholderSize  : 16px;
$inputHeight      : 33px;

$inputTextSize    : 16px;

$labelSize        : 16px;

/* Links */

$linkSize         : 18px;

$submitSize       : 21px;

/* Leads, titles */

$miniTitleSize    : 18px;
